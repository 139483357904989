var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('nav',{style:({
			background: !_vm.$router.currentRoute.path.includes('supplieraccounts')
				? 'var(--v-primary-base)'
				: 'var(--q-primary)',
		})},[_c('div',{staticClass:"navbar"},[_c('div',{staticClass:"gz_flex gz_justify--content"},[_c('div',[_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:('#FFF'),expression:"'#FFF'"}],staticClass:"gz_burger-nav",on:{"click":function($event){return _vm.ShowSidebar()}}},[_c('burger')],1)]),_c('div',{staticStyle:{"cursor":"pointer","user-select":"none"},attrs:{"id":"root-project"}},[_c('span',{staticClass:"gz_title gz_justify--content",attrs:{"id":"root-project-content"},on:{"click":function($event){return _vm.NavigateHome()}}},[_vm._v(_vm._s(_vm.GetProjectName()))])])]),_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"gz_flex gz_justify--content"},[_c('button',{staticClass:"gz_avatar",staticStyle:{"align-items":"center"},style:({
							'background-color': !_vm.$router.currentRoute.path.includes('supplieraccounts')
								? 'var(--v-primary-darken1)'
								: 'var(--q-accent)',
						}),attrs:{"icon":""},on:{"click":function($event){return _vm.ShowLogout()}}},[_vm._v(" "+_vm._s(_vm.user.name .split(' ') .map((n) => n[0]) .join(''))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdown),expression:"showDropdown"}],class:_vm.darkTheme == false ? 'dropdown-content' : 'dropdown-content--dark '},[_c('div',{staticClass:"gz_card"},[_c('div',{staticClass:"gz_card-content"},[_c('p',[_vm._v(_vm._s(_vm.user.name))])]),_c('div',{staticClass:"solid"}),_c('div',{staticClass:"gz_card-actions"},[_c('button',{staticClass:"gz_button",style:({
										background: !_vm.$router.currentRoute.path.includes('supplieraccounts')
											? 'var(--v-primary-base)'
											: 'var(--q-primary)',
									}),on:{"click":function($event){return _vm.Logout()}}},[_vm._v(" LOGOUT ")])])])])])])]),(!_vm.isLoggedIn)?_c('div',{staticClass:"sub-header",staticStyle:{"z-index":"20","left":"0","position":"fixed","top":"50%"},attrs:{"elevation":'3px',"app":"","color":"primary darken-2","dark":""}},[_c('h2',{staticStyle:{"width":"100vw","text-align":"center"}},[_vm._v("Loading Your Front End...")])]):_vm._e()])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }