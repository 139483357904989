<template>
	<div>
		<nav
			:style="{
				background: !$router.currentRoute.path.includes('supplieraccounts')
					? 'var(--v-primary-base)'
					: 'var(--q-primary)',
			}"
		>
			<div class="navbar">
				<div class="gz_flex gz_justify--content">
					<div>
						<button class="gz_burger-nav" @click="ShowSidebar()" v-ripple="'#FFF'">
							<burger></burger>
						</button>
					</div>

					<div id="root-project" style="cursor: pointer; user-select: none">
						<span
							id="root-project-content"
							class="gz_title gz_justify--content"
							@click="NavigateHome()"
							>{{ GetProjectName() }}</span
						>
						<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/></svg> -->
					</div>
				</div>
				<div class="dropdown">
					<div class="gz_flex gz_justify--content">
						<button
							class="gz_avatar"
							style="align-items: center"
							:style="{
								'background-color': !$router.currentRoute.path.includes('supplieraccounts')
									? 'var(--v-primary-darken1)'
									: 'var(--q-accent)',
							}"
							icon
							@click="ShowLogout()"
						>
							{{
								user.name
									.split(' ')
									.map((n) => n[0])
									.join('')
							}}
						</button>

						<div
							v-show="showDropdown"
							:class="darkTheme == false ? 'dropdown-content' : 'dropdown-content--dark '"
						>
							<div class="gz_card">
								<div class="gz_card-content">
									<p>{{ user.name }}</p>
								</div>
								<div class="solid"></div>
								<div class="gz_card-actions">
									<button
										class="gz_button"
										:style="{
											background: !$router.currentRoute.path.includes('supplieraccounts')
												? 'var(--v-primary-base)'
												: 'var(--q-primary)',
										}"
										@click="Logout()"
									>
										LOGOUT
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!isLoggedIn"
				class="sub-header"
				:elevation="'3px'"
				app
				color="primary darken-2"
				dark
				style="z-index: 20; z-index: 20; left: 0; position: fixed; top: 50%"
			>
				<h2 style="width: 100vw; text-align: center">Loading Your Front End...</h2>
			</div>
		</nav>
	</div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');

body {
	background: linen;
	margin: 0;
	font-family: 'Roboto', sans-serif;
}

nav {
	width: 100%;
	height: 64px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

nav .gz_title {
	color: #fff;
	font-size: x-large;
	font-family: 'Roboto', sans-serif !important;
	text-transform: capitalize !important;
}

.navbar {
	display: flex;
	flex-direction: row;
	padding: 0 8px 0 16px;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}
.navbar .menu {
	display: flex;
	height: 100%;
	line-height: 100px;
}

.gz_avatar {
	height: 40px;
	min-width: 40px;
	justify-content: center;
	display: flex;
	width: 35px;
	border-radius: 50%;
	font-family: 'Roboto', sans-serif;
	color: #fff;
	font-size: 14px;
}

.gz_avatar:hover {
	animation: pulse 2.5s;
	box-shadow: 0 0 0 2em transparent;
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
	}
}

.gz_flex {
	display: flex;
}
.solid {
	border-top: 0.2px solid #bbb;
}
.gz_justify--content {
	justify-content: center;
	align-items: center;
}
.gz_justify--content p {
	margin: 0;
}
.gz_flex-1 {
	flex: 1;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: block;
	position: absolute;
	right: 3px;
	top: 40px;
	background-color: #f9f9f9;
	min-width: 248px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 9999;
	border-radius: 5px;
}

.dropdown-content--dark {
	display: block;
	position: absolute;
	right: 3px;
	top: 40px;
	color: #fff;
	background-color: #1e1e1e;
	min-width: 248px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 9999;
	border-radius: 5px;
}
.gz_button {
	border-radius: 5px;
	width: 100%;
	height: 36px;
	font-size: 14px;
	text-transform: capitalize !important;
	font-family: 'Roboto', sans-serif;
	color: #fff;
	background-color: var(--v-primary-base);
}

.gz_burger-nav {
	height: 50px;
	width: 50px;
	box-shadow: inset 0 0 0 2em rgba(var(--v-primary-base), 0.8);
	border-radius: 50%;
}
.gz_burger-nav:hover {
	background-color: rgba(var(--v-secondary-base));
	color: white;
}
.gz_card-actions {
	padding: 8px;
}
.gz_flex-2 {
	flex: 2;
}
@mixin devices($breakpoint) {
	//the name of the mixin is devices
	@if $breakpoint == tablet {
		@media only screen and (max-width: 960px) {
			@content;
		}
	}

	@if $breakpoint == mobile {
		@media only screen and (max-width: 600px) {
			@content;
		}
	}
}
.gz_card-content {
	padding: 16px;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
}
#root-project ::v-deep .div__content {
	width: 25% !important;
}

// .header-bar {
// 	height: var(-header-bar-height) !important;
// 	@include devices(tablet) {
// 	}
// 	::v-deep .divlication--wrap {
// 		position: absolute;
// 		top: 0;
// 	}

// 	.sub-header {
// 		top: -header-bar-height;
// 	}
// }

.gz_burger-nav {
	display: none;
	@include devices(tablet) {
		display: block;
		padding: 12px;
	}
}
.navbar {
	@include devices(tablet) {
		padding-left: 0;
	}
}

::v-deep .v-toolbar__title {
	text-transform: capitalize;
}

.ripple-block {
	background: #333;
	color: #fff;
	min-height: 400px;
	text-align: center;
	padding: 40px;
}

@keyframes gz-ripple {
	0% {
		transform: scale(0.01);
		opacity: 0.2;
	}
	80% {
		transform: scale(100);
		opacity: 0;
	}
}
@-webkit-keyframes gz-ripple {
	0% {
		-webkit-transform: scale(0.01);
		opacity: 0.3;
	}
	100% {
		-webkit-transform: scale(50);
		opacity: 0;
	}
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { logout, user$, isLoggedIn$ } from '@gz/auth';
import router from './router';
import Burger from './components/Burger.vue';

@Component({
	components: { Burger },
})
export default class App extends Vue {
	data: any[] = [];
	menu = false;
	isLoggedIn = false;
	user = { name: 'User' };
	router = router;
	localUser$ = user$;
	localLoggedIn$ = isLoggedIn$;
	showSidebar = false;
	popupButton = null;
	isPopupActive = false;
	darkTheme = false;
	showDropdown = false;
	$route: any;

	NavigateHome(): void {
		var title = document.getElementById('root-project')?.innerText.toLowerCase();

		if (title === 'support') {
			title = 'customer support';
		}

		title = title?.replaceAll(' ', '');

		if (title === 'supplieraccounts') {
			window.location.replace('/supplieraccounts');
		}

		this.router.push('/' + title);
	}
	ShowLogout(): void {
		this.$nextTick(() => {
			this.showDropdown = !this.showDropdown;
		});
	}

	mounted(): void {
		document.addEventListener('click', this.CloseDropdownOnClickOutside);
	}

	GetProjectName() {
		let val = this.$route.path.split('/')[1]?.toLowerCase() || 'Support';

		if (val === 'customersupport') {
			val = 'Support';
		}

		if (val === 'supplieraccounts') {
			val = 'Supplier Accounts';
		}

		// fix for navigating back and forward in browser not updating content
		var val2 = document.getElementById('root-project-content');

		if (document.getElementById('root-project-content')) {
			(val2 as any).innerHTML = val;
		}

		return val;
	}

	CloseDropdownOnClickOutside(event: MouseEvent): void {
		const targetElement = event.target as HTMLElement;
		const dropdownContent = document.getElementsByClassName('gz_card')[0];
		const dropdownButton = document.getElementsByClassName('gz_avatar')[0];
		if (!dropdownContent.contains(targetElement) && !dropdownButton.contains(targetElement)) {
			this.showDropdown = false;
		}
	}
	ShowSidebar(): void {
		const element = document.getElementsByClassName('v-navigation-drawer')[0];
		const classList = element.classList;
		const exists = [...classList].find((classes) => classes.includes('v-navigation-drawer--close'));

		if (exists) {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(0)';
		} else {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(-100%)';
		}
	}
	Logout(): void {
		logout();
	}

	created(): void {
		this.localUser$.subscribe((user: any) => {
			if (user) {
				this.user = user;
			}
		});

		this.localLoggedIn$.subscribe((isLoggedIn: boolean) => {
			setTimeout(() => {
				this.isLoggedIn = isLoggedIn;
			}, 500);
		});

		const theme = localStorage.getItem('dark_theme');
		if (theme) {
			if (theme === 'true') {
				this.darkTheme = true;
			} else {
				this.darkTheme = false;
			}
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			// this.$vuetify.theme.dark = true;
			// localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
		}
	}

	destroyed(): void {
		this.localUser$.unsubscribe();
		this.localLoggedIn$.unsubscribe();
	}
}
</script>
